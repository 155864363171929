// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, change, reduxForm} from 'redux-form'

import Title from '../../../../Title/'
import EntitlementsTable from './EntitlementsTable/'
import {FormGroup, InputField} from '../../../../form/'

import {fetchEntitlements, clearEntitlements} from '../../../../../actions/entitlements'

import SearchIcon from '@material-ui/icons/Search'

import './index.css'

const DEVICE_ID = 'Device ID'

class Entitlements extends Component {
  constructor (props) {
    super(props)

    this.state = {
      entitlements: null,
      deviceID: null
    }
  }

  componentWillUnmount () {
    this.props.clearEntitlements()
  }

  deviceIdChange = (event) => {
    this.setState({deviceID: event.target.value})
  }


  renderSearchCriteria () {
    const {handleSubmit, submitting, invalid} = this.props

    return (
      <div className='col'>
        <form className='form entitlements-searchcriteria-form' onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-3'>
              <div className='row'>
                <label className='description'>Device ID</label>
              </div>
              <FormGroup>
                <div className='row'>
                  <div className='col'>
                    <Field
                      name='deviceID'
                      type='text'
                      component={InputField}
                      className='device-id'
                      placeholder='Enter full ID'
                      value={this.state.deviceID}
                      onChange={this.deviceIdChange}
                    />
                  </div>
                  <div className='col icon'>
                    <SearchIcon/>
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className='col-md-5 align-bottom button-container'>
              <FormGroup>
                <div className='row'>
                  <div className='col col-md-2'>
                    <button className='btn btn-search' type='submit' disabled={invalid || submitting}>Search</button>
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render () {
    return (
      <div className='entitlements'>
        <Title>Entitlements</Title>
        <div className='row'>
          <div className='col'>
            <span className='title'>Search Criteria</span>
          </div>
        </div>
        <div className='row'>
          {this.renderSearchCriteria()}
        </div>
        <div className='row mt-5'>
          <div className='col'>
            <EntitlementsTable
              entitlements={this.props.entitlements}
              deviceId={this.state.deviceID}
            />
          </div>
        </div>
      </div>
    )
  }
}

function validate (values) {
  let ret = {}

  if (!values.deviceID || !values.deviceID.trim()) {
    ret = {...ret, deviceID: 'Device Id cannot be blank'}
  }

  return ret
}

function onSubmit (values, dispatch) {
  dispatch(fetchEntitlements(values))
}

function mapStateToProps ({entitlementManagement}) {
  return {
    entitlements: entitlementManagement.entitlements
  }
}

let EntitlementsForm = connect(mapStateToProps, {fetchEntitlements, clearEntitlements, change})(Entitlements)

export default reduxForm({
  form: 'EntitlementsSearchCriteria',
  onSubmit: onSubmit,
  validate: validate,
  initialValues: {
    searchBy: DEVICE_ID
  }
})(EntitlementsForm)
