// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import {Area, Line} from 'recharts'

import {COLORS, looker_items} from '../'

function compare (key, order = 'asc') {
  let dateFormat = 'YYYY-MM-DD hh:mm:ss'

  return function innerSort (a, b) {
    let comparison = 0

    let aDate = moment(a[key], dateFormat)
    let bDate = moment(b[key], dateFormat)

    if (aDate.isAfter(bDate)) {
      comparison = 1
    } else if (bDate.isAfter(aDate)) {
      comparison = -1
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}

export function getXAxisDataKey (queryResult) {
  let xAxisKey = null
  if (queryResult && queryResult.result && queryResult.result[0]) {
    let element_keys = Object.keys(queryResult.result[0])
    xAxisKey = element_keys[0]
  }

  return xAxisKey
}

export function generateLinesOrAreas (queryResult, disabled, ignoreKeys, colors) {
  let flatteednObject = flattenQueryResult(queryResult)

  if (flatteednObject) {
    let elementKeys = Object.keys(flatteednObject)

    let result = elementKeys.map((key) => {
      let index = 0
      if (!_.includes(ignoreKeys, key)) {
        index += 1
        const active = !(_.includes(disabled, key))

        switch (queryResult.type) {
          case looker_items.areaChart:
            return (
              active && <Area isAnimationActive={false} type='monotone' dataKey={key} key={`${key}${index}`} stackId={key} stroke={colors[key]} fill={colors[key]} />
            )
          case looker_items.lineChart:
            return (
              active && <Line isAnimationActive={false} type='monotone' dataKey={key} key={`${key}${index}`} stackId={key} stroke={colors[key]} dot={false}/>
            )
          default:
            return null
        }
      }

      return null
    })

    return result
  }

  return null
}

function flattenObject (o, prefix = '', result = {}, keepNull = true) {
  if (_.isString(o) || _.isNumber(o) || _.isBoolean(o) || (keepNull && _.isNull(o))) {
    if (keepNull && _.isNull(o)) {
      result[prefix] = 0
      return result
    }

    result[prefix] = o
    return result
  }

  if (_.isArray(o) || _.isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix
      if (_.isArray(o)) {
        pref = `${pref}[${i}]`
      } else {
        if (_.isEmpty(prefix)) {
          pref = i
        } else {
          pref = `${prefix}.${i}`
        }
      }
      flattenObject(o[i], pref, result, keepNull)
    }
    return result
  }
  return result
}

export function flattenQueryResult (queryResult) {
  if (queryResult.result.length > 0 ) {
    const element = queryResult.result[0]
    let flattenedObject = flattenObject(element)
    return flattenedObject
  }

  return null
}

export function generateDots (queryResult, xAxisKey) {
  let data = []

  for (const queryResultIndex in queryResult.result) {
    let element = queryResult.result[queryResultIndex]
    let flattenedElement = flattenObject(element)

    let dot = {
      ...flattenedElement
    }

    data.push(dot)
  }

  data.sort(compare(xAxisKey))
  return data
}

export function generateLegendData (queryResult, ignoreKeys) {
  let flattenedElement = flattenQueryResult(queryResult)
  let colors = {}

  if (flattenedElement) {
    let index = 0

    let elementKeys = Object.keys(flattenedElement)
    elementKeys.forEach(key => {
      if (!_.includes(ignoreKeys, key) && !colors[key]) {
        colors[key] = COLORS[index % COLORS.length]
        index += 1
      }
    })
  }

  return colors
}
