import React from 'react'
import {Link} from 'react-router-dom'
import {Field} from 'redux-form'
import {FormGroup, InputField, DropDownListField, MultiselectField} from '../components/form'
import DropDownList from 'react-widgets/lib/DropdownList'
import DateTimePickerField from '../components/form/DateTimePickerField'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


export function renderFormField (name, type, placeholder, className, value = null, props = {}) {
  return (
    <FormGroup className={className}>
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        component={InputField}
        className='rw-widget-input rw-widget-container'
        value={value}
        {...props}
      />
    </FormGroup>
  )
}

export function renderFormSelectField (name, data, valueField, textField, className, props = {}) {
  return (
    <FormGroup className={className}>
      <Field
        name={name}
        data={data}
        component={DropDownListField}
        valueField={valueField}
        textField={textField}
        {...props}
      />
    </FormGroup>
  )
}

export function renderMultiSelectField (name, data, valueField, textField, className, props = {}) {
  return (
    <FormGroup className={className}>
      <Field
        name={name}
        data={data}
        component={MultiselectField}
        valueField={valueField}
        textField={textField}
        {...props}
      />
    </FormGroup>
  )
}

export function renderDateField (name, className, date, time, inputReadOnly, props = {}) {
  return (
    <FormGroup >
      <Field
        name={name}
        className={className}
        type='text'
        date={date}
        dateIcon={<CalendarTodayOutlinedIcon/>}
        time={time}
        timeIcon={ <AccessTimeIcon/>}
        inputReadOnly={inputReadOnly}
        component={DateTimePickerField}
        {...props}
      />
    </FormGroup>
  )
}

export function renderMultiField (array, dropdownText, maxDisplayItems, props = {}) {
  if (array.length <= maxDisplayItems) {
    return (
      <ul>
        {array.map((arrayItem) => {
          return (
            <div className='row'>
              <Link className='id'>{arrayItem}</Link>
            </div>
          )
        }
        )}
      </ul>
    )
  } else {
    let text = `${array.length - 1} ${dropdownText}`
    return (
      <DropDownList
        data={array}
        disabled={false}
        selectIcon={<ExpandMoreIcon/>}
        defaultValue={text}
        containerClassName='dropdown'
        {...props}
      />
    )
  }
}
