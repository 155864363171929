
import {BIG_BUTTONS_FETCH_SERVICES} from '../actions/bigButtons'

const initialState = {
  bigButtonsStatus: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case BIG_BUTTONS_FETCH_SERVICES:
      return {...state, bigButtonsStatus: action.payload}
    default:
      return state
  }
}
