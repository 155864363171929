import axios from 'axios'
import {APIUrl, APIHeaders} from './utils'
import {SMI_API} from '../config'
import {errorToast} from './toastMessages'
import {unexpectedApiError} from './apiErrors'

import {showComponentSpinner, ON_SEARCH} from './spinner'
import {} from './spinner'

export const FETCH_ENTITLEMENTS = 'fetch_entitlements'
export const CLEAR_ENTITLEMENTS = 'clear_entitlements'

export function fetchEntitlements (filters) {
  return (dispatch, getState) => {
    const {session} = getState()
    const url = APIUrl('entitlements/', {}, SMI_API)

    dispatch(showComponentSpinner(true, ON_SEARCH))
    dispatch(clearEntitlements())

    return axios.get(url, {headers: APIHeaders(session)}).then( response => {

      let result = response.data.data

      if (filters.deviceID != null) {
        result = result.filter(entitlement => entitlement.deviceId === filters.deviceID)
      }

      dispatch(showComponentSpinner(false))

      dispatch({
        type: FETCH_ENTITLEMENTS,
        payload: result
      })
    }).catch((error) => {
      dispatch(showComponentSpinner(false))
      if (error.response) {
        dispatch(errorToast(error.response.data.message))
      } else {
        dispatch(unexpectedApiError())
      }
    })
  }
}

export function clearEntitlements () {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ENTITLEMENTS,
    })
  }
}
