// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'
import {NavLink, Route, Switch} from 'react-router-dom'

import {VCASCLOUD_PATH, DEVICES_PATH, DOMAINS_PATH} from '../navigation'
import {MULTIDRM_PATH} from '../../navigation'

import Title from '../../../../Title/'

import DevicesTable from './DevicesTable/'
import DomainsTable from './DomainsTable/'

import config, {FEATURE_VCASCLOUD_DEVICES} from '../../../../../config'

import './index.css'

const devices_flag = config[FEATURE_VCASCLOUD_DEVICES]

export default class Devices extends Component {
  menuItems () {
    return [
      <li className='nav-item' key='profiles'>
        <NavLink exact className='nav-link' to={`/${MULTIDRM_PATH}/${VCASCLOUD_PATH}/${DEVICES_PATH}/`} key='devices' id='devices'> Devices </NavLink>
      </li>,
      <li className='nav-item' key='products'>
        <NavLink className='nav-link' to={`/${MULTIDRM_PATH}/${VCASCLOUD_PATH}/${DEVICES_PATH}/${DOMAINS_PATH}/`} key='domains' id='domains'> Domains </NavLink>
      </li>
    ]
  }

  render () {
    return (
      <div className='align-items-center'>
        <div className='devices'>
          <Title> Devices </Title>
          <div className='row'>
            <div className='col-12'>
              <div>
                <ul className='nav nav-tabs'>
                  {this.menuItems()}
                </ul>
              </div>
              <div className='inner-div'>
                <Switch>
                  <Route path={`/${MULTIDRM_PATH}/${VCASCLOUD_PATH}/${DEVICES_PATH}/${DOMAINS_PATH}/`} component={DomainsTable}/>
                  {devices_flag && <Route exact path={`/${MULTIDRM_PATH}/${VCASCLOUD_PATH}/${DEVICES_PATH}/`} component={DevicesTable}/>}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
