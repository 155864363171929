import React, {Component} from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import {AreaChart, CartesianGrid, Tooltip, XAxis, YAxis, Legend, ResponsiveContainer, Surface, Symbols} from 'recharts'
import {GridLoader} from 'qualabs-react-spinners'
import {getXAxisDataKey} from '../ChartUtils'

import {generateDots, generateLegendData, generateLinesOrAreas} from '../ChartUtils/'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

export default class LookerAreaChart extends Component {
  constructor (props) {
    super(props)

    this.state = {
      disabled: []
    }
  }

  handleClick = (dataKey) => {
    if (_.includes(this.state.disabled, dataKey)) {
      this.setState({
        disabled: this.state.disabled.filter(obj => obj !== dataKey)
      })
    } else {
      this.setState({disabled: this.state.disabled.concat(dataKey)})
    }
  }

  renderCusomizedLegend = ({payload}) => {
    return (
      <div className='customized-legend d-flex justify-content-center'>
        {payload.map(entry => {
          const {color, dataKey} = entry
          const active = _.includes(this.state.disabled, dataKey)

          let dataKeyText = dataKey.replace(/\./g, ' ')

          const style = {
            marginRight: 10,
            color: active ? '#AAA' : '#000'
          }

          return (
            <span
              className='legend-item'
              onClick={() => this.handleClick(dataKey)}
              style={style}
              key={dataKey}
            >
              <Surface width={10} height={10} viewBox='0 0 10 10'>
                <Symbols cx={5} cy={5} type='circle' size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type='circle'
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span style={{color}}>{dataKeyText}</span>
            </span>
          )
        })}
      </div>
    )
  }

  renderChart () {
    let {queryResult} = this.props
    if (!queryResult) {
      return (
        <div className='chart-spinner'>
          <span>Updating data</span><GridLoader />
        </div>
      )
    }

    if (queryResult.error) {
      return (
        <div className='col query-error'>
          <span><ErrorIcon />&nbsp; {queryResult.error}</span>
        </div>
      )
    }

    if (queryResult.result.length === 0) {
      return (
        <div className='col no-results'>
          <span><WarningIcon />&nbsp; No data available</span>
        </div>
      )
    }

    let xAxisDataKey = getXAxisDataKey(queryResult)

    let hidden_fields = _.values(queryResult.hidden_fields)
    let ignoreKeys = _.concat(hidden_fields, [xAxisDataKey])

    let colors = generateLegendData(queryResult, ignoreKeys)

    let data = generateDots(queryResult, xAxisDataKey)

    return (
      <ResponsiveContainer witdh={this.props.width || '100%'} height={this.props.height || 300}>
        <AreaChart data={data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={this.props.xAxisDataKey} />
          <YAxis />
          <Tooltip
            allowEscapeViewBox={{x: true, y: true}}
          />
          <Legend
            content={this.renderCusomizedLegend}
            payload={_.toPairs(colors).map(pair => ({
              dataKey: pair[0],
              color: pair[1]
            }))}
          />
          {generateLinesOrAreas(queryResult, this.state.disabled, ignoreKeys, colors)}
        </AreaChart>
      </ResponsiveContainer>
    )
  }

  render () {
    let {title, queryResult} = this.props
    let warning = queryResult && queryResult.result.length === 0
    let error = queryResult && queryResult.error
    let classNames = classnames('background', {warning: warning, error: error})

    return (
      <div className={`background ${classNames}`}>
        <div className='title'>
          <span>{title}</span>
        </div>
        <div className='chart area-chart'>
          {this.renderChart()}
        </div>
      </div>
    )
  }
}
