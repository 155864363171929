import React, {Component} from 'react'
import {connect} from 'react-redux'

import PropagateLoader from 'qualabs-react-spinners/PropagateLoader'

import './index.css'

export class Spinner extends Component {

  render () {
    return (
      this.props.spinner.show &&
        <div className='spinner'>
          <PropagateLoader color={'#E8308A'} />
        </div>
    )
  }
}


function mapStateToProps ({spinner}) {
  return {spinner}
}

export default connect(mapStateToProps, {})(Spinner)
