
import {
  CHECK_UPDATE,
  UPDATE_DATE_NOW
} from '../actions/app'

const initialState = {
  lastUpdateCheck: null,
  dateNow: new Date()
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_UPDATE:
      return {...state, lastUpdateCheck: new Date()}
    case UPDATE_DATE_NOW:
      return {...state, dateNow: new Date()}
    default:
      return state
  }
}
