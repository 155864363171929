// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'

import Table from '../../../../../Table/'

import './index.css'

export default class EntitlementsTable extends Component {

  getFormatedDate = (date) => {
    if (date != null) {
      let month = date.toLocaleString('default', {month: 'long'})
      let formatedDate = `${month} ${date.getDate()}, ${date.getFullYear()}`

      return (formatedDate)
    }

    return null
  }

  getFormatedTime = (time) => {
    if (time != null) {
      let formatedTime = time.toLocaleString('default', {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true})
      return (formatedTime)
    }

    return null
  }

  tableHeaders () {
    return (
      <>
        <div className='th col-md-1 align-bottom'>
          <span className='max-content'>Entitlement ID</span>
        </div>
        <div className='th col-md-1 offset-md-1 align-bottom'>
          <span>Package ID</span>
        </div>
        <div className='th col-md-1 offset-md-1 align-bottom'>
          <span className='max-content'><CalendarTodayOutlinedIcon/>&nbsp; Start Date</span>
        </div>
        <div className='th col-md-2 align-bottom'>
          <span className='max-content'><AccessTimeIcon/>&nbsp; Start Time (GMT)</span>
        </div>
        <div className='th col-md-1 align-bottom'>
          <span className='max-content'><CalendarTodayOutlinedIcon/>&nbsp; End Date</span>
        </div>
        <div className='th col-md-1 align-bottom'>
          <span className='max-content'><AccessTimeIcon/>&nbsp; End Time (GMT)</span>
        </div>
      </>
    )
  }

  infoFields = (item) => {
    let startDate = 'None'
    let endDate = 'None'
    let startTime = 'None'
    let endTime = 'None'

    if (item.startTime) {
      startDate = this.getFormatedDate(new Date(item.startTime))
      startTime = this.getFormatedTime(new Date(item.startTime))
    }

    if (item.endTime) {
      endDate = this.getFormatedDate(new Date(item.endTime))
      endTime = this.getFormatedTime(new Date(item.endTime))
    }

    return (
      <>
        <div className='td col-md-1'>
          <span className='max-content'>{item.entitlementId}</span>
        </div>
        <div className='td col-md-1 offset-md-1'>
          <span className='max-content'>{item.packageId}</span>
        </div>
        <div className='td col-md-1 offset-md-1'>
          <span className='max-content'>{startDate}</span>
        </div>
        <div className='td col-md-2'>
          <span className='max-content'>{startTime}</span>
        </div>
        <div className='td col-md-1'>
          <span className='max-content'>{endDate}</span>
        </div>
        <div className='td col-md-1'>
          <span className='max-content'>{endTime}</span>
        </div>
      </>
    )
  }

  render () {
    if (this.props.entitlements) {

      const entitlementsLength = this.props.entitlements.length

      if (entitlementsLength > 0) {
        return (
          <div className='col entitlements-table'>
            <div className='row'>
              <div className='col'>
                <span className='title'>Entitlements</span>
              </div>
            </div>
            <div className='row'>
              <div className='device-id col'>
                <div className='row device-id-title align-bottom'>
                  <span className='device-id-header'> Device ID</span>
                  <div className='col placeholder-icon'>
                    {/* Placeholder to keep same size in rows*/}
                    <CalendarTodayOutlinedIcon style={{fill: 'white'}}/>
                  </div>
                </div>
                <div className='row device-id-text'>
                  <span>{this.props.deviceId}</span>
                </div>
              </div>
              <Table
                items={this.props.entitlements}
                tableHeaders={this.tableHeaders()}
                pinkBackground={false}
                infoFields={this.infoFields}
              />
            </div>
          </div>
        )
      } else {
        return (
          <div className='col no-results'>
            <div className='row'>
              <div className='col'>
                <span className='text'>No results found</span>
              </div>
            </div>
          </div>
        )
      }
    }

    return null
  }
}
