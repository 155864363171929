// Copyright © 2020 Verimatrix, Inc. All rights reserved.
import React, {Component} from 'react'
import {reduxForm, Field} from 'redux-form'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import _ from 'lodash'

import {lookerGetDashboard, lookerInlineQuery, clearQueries} from '../../actions/looker'
import LookerPieChart from './LookerPieChart'
import LookerSingleValue from './LookerSingleValue/'
import LookerLineChart from './LookerLineChart/'
import LookerAreaChart from './LookerAreaChart/'
import LastRefreshDate from '../LastRefreshDate'

import Title from '../Title/'

import {FormGroup} from '../form'
import DateTimePickerField from '../form/DateTimePickerField'

import {warningToast} from '../../actions/toastMessages'
import {IGNORED_ITEMS} from '../../actions/looker'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'

import './index.css'

function convertHex (hex, opacity) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const result = `rgba(${r},${g},${b},${opacity / 100})`
  return result
}

function getColors (step) {
  let firstBaseColor = '#005CA9'
  let secondBaseColor = '#2D80C6'

  let firstColors = []
  let secondColors = []

  for (let i = 100; i >= 20; i -= step) {
    firstColors.push(convertHex(firstBaseColor, i))
    secondColors.push(convertHex(secondBaseColor, i))
  }

  let colors = _.concat(firstColors, secondColors)
  return colors
}

export const COLORS = getColors(20)

export const dashboards_ids = {
  api_activity: 378,
  iptv_activity: 379,
  ott_activity: 380,
  multidrm_activity: 381
}

export const looker_items = {
  singleValue: 'single_value',
  pieChart: 'looker_pie',
  areaChart: 'looker_area',
  lineChart: 'looker_line'
}

class LookerDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dateFrom: this.props.initialValues.dateFrom,
      timeFrom: this.props.initialValues.timeFrom,
      dateTo: this.props.initialValues.dateTo,
      timeTo: this.props.initialValues.timeTo
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.dashboardId !== nextProps.dashboardId) {
      this.props.lookerGetDashboard(nextProps.dashboardId)
        .then(() => {
          this.fetchDashboardData()
        })
    }
  }

  componentDidMount () {
    this.props.lookerGetDashboard(this.props.dashboardId)
      .then(() => {
        this.fetchDashboardData()
      })
  }

  checkTilesForErrors = (dashboard_element) => {
    let {queryResults} = this.props

    if (queryResults) {
      let queryResult = queryResults[dashboard_element.id]

      let error = queryResult && queryResult.error
      let warning = queryResult && queryResult.result.length === 0

      if (error || warning) {
        this.props.warningToast(<><b>Warning:</b>&nbsp; There is some data that is not available right now.</>)
        return true
      }
    }
  }

  fetchDashboardData = () => {
    this.props.clearQueries()
    let {dashboard} = this.props

    let filters = {
      dateFrom: this.state.dateFrom,
      timeFrom: this.state.timeFrom,
      dateTo: this.state.dateTo,
      timeTo: this.state.timeTo
    }

    let errorFound = false

    dashboard.dashboard_elements.forEach(dashboard_element => {
      this.props.lookerInlineQuery(dashboard_element, filters).then( () => {
        if (!errorFound) {
          errorFound = this.checkTilesForErrors(dashboard_element)
        }
      })
    })
  }
  // TODO: Look for a way to unify renderCards and renderCharts
  renderCards = () => {
    let {queryResults, dashboard} = this.props
    let keys = null
    keys = Object.keys(dashboard.dashboard_elements)
    let cards = keys.map((key, index) => {
      let element = dashboard.dashboard_elements[key]
      let {type} = element.result_maker.vis_config
      let {title, id} = element
      let queryResult = queryResults && queryResults[element.id] ? queryResults[element.id] : null

      if (IGNORED_ITEMS.includes(title)) {
        return null
      }

      switch (type) {
        case looker_items.singleValue:
          return <LookerSingleValue queryResult={queryResult} title={title} id={id} key={`${key}${index}`} />
        default:
          return null
      }
    })
    return cards
  }

  renderCharts = () => {
    let {queryResults, dashboard} = this.props

    let keys = null
    keys = Object.keys(dashboard.dashboard_elements)
    let charts = keys.map((key, index) => {
      let element = dashboard.dashboard_elements[key]
      let {type} = element.result_maker.vis_config
      let {title, id} = element
      let queryResult = queryResults && queryResults[element.id] ? queryResults[element.id] : null
      switch (type) {
        case looker_items.pieChart:
          return (
            <div key={id} className='pie-chart-container chart-container col-12 col-sm-12 col-md-6 col-lg-6'>
              <LookerPieChart element={element} queryResult={queryResult} title={title} key={`${key}${index}`} />
            </div>
          )
        case looker_items.areaChart:
          return (
            <div key={id} className='area-chart-container chart-container col-12 col-sm-12 col-md-6 col-lg-6'>
              <LookerAreaChart element={element} queryResult={queryResult} title={title} key={`${key}${index}`} />
            </div >
          )
        case looker_items.lineChart:
          return (
            <div key={id} className='line-chart-container chart-container col-12 col-sm-12 col-md-6 col-lg-6'>
              <LookerLineChart element={element} queryResult={queryResult} title={title} key={`${key}${index}`} />
            </div>
          )
        default:
          return null
      }
    })
    return charts

  }

  dateFromChange = (value) => {
    this.setState({dateFrom: value})
  }

  timeFromChange = (value) => {
    this.setState({timeFrom: value})
  }

  dateToChange = (value) => {
    this.setState({dateTo: value})
  }

  timeToChange = (value) => {
    this.setState({timeTo: value})
  }

  renderDateTimePicker (key, date, dateIcon, time, timeIcon, inputReadOnly, value, onChange, min, max) {
    return (
      <FormGroup >
        <Field
          key={key}
          name={key}
          type='text'
          date={date}
          dateIcon={dateIcon}
          time={time}
          timeIcon={timeIcon}
          inputReadOnly={inputReadOnly}
          component={DateTimePickerField}
          value={value}
          onChange={onChange}
          max={max}
          min={min}
        />
      </FormGroup>
    )
  }

  renderFilters = () => {
    let {handleSubmit} = this.props
    return (
      <form className='row form dasboard-filters-form inline-form round-border-fields with-background' onSubmit={handleSubmit(this.fetchDashboardData)}>
        <div className='col-md-10 filters'>
          <div className='row'>
            <div className='col-6 col-xl-3'>
              <div className='form-item'>
                <span>Start on &nbsp;</span>
                {this.renderDateTimePicker('dateFrom', true, <CalendarTodayOutlinedIcon/>, false, null, true, this.state.dateFrom, this.dateFromChange, null, {max: this.state.dateTo})}
              </div>
            </div>
            <div className='col-6 col-xl-3'>
              <div className='form-item'>
                <span>at &nbsp;</span>
                {this.renderDateTimePicker('timeFrom', false, null, true, <AccessTimeIcon />, true, this.state.timeFrom, this.timeFromChange, null, {max: this.state.timeTo})}
              </div>
            </div>
            <div className='col-6 col-xl-3'>
              <div className='form-item'>
                <span>Stop on &nbsp;</span>
                {this.renderDateTimePicker('dateTo', true, <CalendarTodayOutlinedIcon/>, false, null, true, this.state.dateTo, this.dateToChange, {min: this.state.dateFrom}, null)}
              </div>
            </div>
            <div className='col-6 col-xl-3'>
              <div className='form-item'>
                <span>at &nbsp;</span>
                {this.renderDateTimePicker('timeTo', false, null, true, <AccessTimeIcon />, true, this.state.timeTo, this.timeToChange, {min: this.state.timeFrom}, null)}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 submit-button'>
          <div className='form-item col'>
            <button className='btn-with-icon btn-go' type='submit'>
              <span>Go</span>
              <div className='icon'>
                <ArrowForwardIosIcon />
              </div>
            </button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    if (this.props.dashboard) {
      return (
        <div className='looker-dashboard'>
          <Title>
            VCAS Activity
          </Title>
          <div className='inner-title row'>
            Data visualization
            <LastRefreshDate date={this.props.last_refresh_date}/>
          </div>
          <div className='looker-dashboard-filters'>
            {this.renderFilters()}
          </div>
          <div className='row looker-dashboard-cards'>
            {this.renderCards()}
          </div>
          <div className='row looker-dashboard-charts'>
            {this.renderCharts()}
          </div>
        </div>
      )
    }
    return null
  }
}

function mapStateToProps ({looker}) {
  return {
    last_refresh_date: looker.last_refresh_date,
    dashboard: looker.dashboard,
    queryResults: looker.queryResults,
  }
}

const LookerDashboardRedux = withRouter(connect(mapStateToProps, {lookerGetDashboard, lookerInlineQuery, clearQueries, warningToast})(LookerDashboard))

const dateFrom = new Date()
dateFrom.setDate(dateFrom.getDate() - 1)
dateFrom.setHours(0, 0, 0)

const dateTo = new Date()
dateTo.setDate(dateTo.getDate() - 1)
dateTo.setHours(23, 59, 59)


export default reduxForm({
  form: 'LookerDashboardFilters',
  initialValues: {
    dateFrom: dateFrom,
    timeFrom: dateFrom,
    dateTo: dateTo,
    timeTo: dateTo
  }
})(LookerDashboardRedux)
