// Copyright © 2019 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'
import Dropdown from 'react-simple-dropdown'
import {DropdownTrigger, DropdownContent} from 'react-simple-dropdown'
import onClickOutside from 'react-onclickoutside'

import './index.css'

class DropdownWithClickOutside extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeDropdown: false
    }
  }

  handleClickOutside = () => {
    this.setState({activeDropdown: false})
  }

  toggleDropdown = () => {
    const active = this.state.activeDropdown
    this.setState({activeDropdown: !active})
  }

  render () {
    return (
      <div className={`dropdown-div ${this.props.className}`} onClick={this.toggleDropdown}>
        <Dropdown ref={(r) => this.dropdown = r} active={this.state.activeDropdown}>
          <DropdownTrigger className={this.props.dropdownTriggerClassname}>
            {this.props.dropdownTrigger}
          </DropdownTrigger>
          <DropdownContent>
            {this.props.children}
          </DropdownContent>
        </Dropdown>
      </div>
    )
  }
}

let CustomDropdown = onClickOutside(DropdownWithClickOutside)
export default CustomDropdown
