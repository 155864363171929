import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import {showComponentSpinner, ON_FETCH} from './spinner'

import {fetchHosts} from './multiDRM'

import {APIUrl, APIHeaders} from './utils'
import {SSI_API} from '../config'

import {errorToast} from './toastMessages'

export const BIG_BUTTONS_FETCH_SERVICES = 'bit_buttons_fetch_licenses'

export function fetchBigButtonsStatus () {
  return (dispatch, getState) => {
    const {session} = getState()
    const url = APIUrl('license/', {}, SSI_API)

    dispatch(showComponentSpinner(true, ON_FETCH))
    dispatch(fetchHosts()).then( () => {
      let {multiDRM} = getState()
      let {system} = multiDRM.system

      const axiosMocked = new MockAdapter(axios, {delayResponse: 3000})
      axiosMocked.onGet(url).reply(200, getMockedLicenses())

      return axios.get(url, {headers: APIHeaders(session)}).then( response => {
        axiosMocked.restore()
        let licenses_status = response.data

        let activeDashboards = {}
        activeDashboards['MULTIRIGHTSDashboard'] = JSON.parse(licenses_status['MULTIRIGHTS_ENABLED'])
        activeDashboards['IPTVDashboard'] = JSON.parse(licenses_status['IPTV_ENABLED'])
        activeDashboards['OTTDashboard'] = JSON.parse(licenses_status['OTT_ENABLED'])
        activeDashboards['APIDashboard'] = system.DRM_MODE === 'OMI'

        dispatch({
          type: BIG_BUTTONS_FETCH_SERVICES,
          payload: activeDashboards
        })
        dispatch(showComponentSpinner(false))

      }).catch( () => {
        dispatch(showComponentSpinner(false))
        dispatch(errorToast(`There was an error trying to get the licenses`))
        axiosMocked.restore()
      })
    })
  }
}

function getMockedLicenses () {
  return {
    'ON_SCREEN_DISPLAY': 'true',
    'VIS_ENABLED': 'true',
    'CURRENT_STREAM_COUNT_IPTV': '0',
    'MAX_PLATFORM_COUNT': '1000000',
    'KEY_MANAGEMENT_ENABLED': 'true',
    'COPY_CONTROL': 'true',
    'SM_INTERVAL_DURATION_TYPE1': '300',
    'SM_INTERVAL_DURATION_TYPE2': '500',
    'MAX_WHOLESALER_COUNT': '9',
    'CURRENT_PLATFORM_COUNT': '1',
    'VOD_ENCRYPTION': 'true',
    'FAIRPLAY_ENABLED': 'true',
    'INTERNET_STREAM_COUNT': '3333',
    'MULTIRIGHTS_ENABLED': 'true',
    'MULTIRIGHTS_FAIRPLAY_ENABLED': 'true',
    'PUBLIC_DEVICE_AUTHENTICATION': 'true',
    'SM_TRANSACTIONID_LENGTH_TYPE1': '24',
    'STREAMMARK_PREPROCESSOR_ENABLED': 'true',
    'SM_TRANSACTIONID_LENGTH_TYPE2': '27',
    'MAX_CHANNEL_COUNT': '4096',
    'VIDEOMARK': 'true',
    'SYSTEM_OBJECT_ID': 'FFEEDDCCBBAA99887766554433221100',
    'STREAMMARK_PAYLOADMANAGER_ENABLED': 'true',
    'MULTIRIGHTS_WIDEVINE_ENABLED': 'true',
    'MULTIRIGHTS_PLAYREADY_ENABLED': 'true',
    'GDPR_MODE': 'CONFIGURABLE',
    'RETAILER': 'true',
    'SM_CONFIGURATIONID': '120',
    'WHOLESALER': 'true',
    'CURRENT_STREAM_COUNT_ITV': '0',
    'MAX_RETAILER_COUNT': '9',
    'SITE_ID': '999',
    'IPTV_ENABLED': 'true',
    'MULTIRIGHTS_ADOBE_ENABLED': 'true',
    'PROVISION_WITH_EXPIRED_CERTIFICATE': 'false',
    'CURRENT_RETAILER_COUNT': '0',
    'CURRENT_WHOLESALER_COUNT': '0',
    'ULTRA_ENABLED': 'true',
    'OTT_ENABLED': 'true',
    'LICENSE_END_DATE': '20191230'
  }
}
