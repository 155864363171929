// Copyright © 2020 Verimatrix, Inc. All rights reserved.
import React, {Component} from 'react'
import {Cell, Pie, PieChart, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import {GridLoader} from 'qualabs-react-spinners'
import classnames from 'classnames'

import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

import {COLORS} from '..'

export default class LookerPieChart extends Component {
  renderColorfulLegendText (name, entry) {
    const {color, payload} = entry
    const {percent} = payload
    const transformed_percent = (percent * 100).toFixed(2)
    return <span style={{color}}>{`${name} ${transformed_percent}%`}</span>
  }

  renderChart () {
    let {queryResult} = this.props

    if (!queryResult) {
      return (
        <div className='chart-spinner'>
          <span>Updating data</span><GridLoader/>
        </div>
      )
    }

    if (queryResult.error) {
      return (
        <div className='col query-error'>
          <span><ErrorIcon />&nbsp; {queryResult.error}</span>
        </div>
      )
    }

    if (queryResult.result.length === 0) {
      return (
        <div className='col no-results'>
          <span><WarningIcon />&nbsp; No data available</span>
        </div>
      )
    }

    let keys = [null, null]
    if (queryResult && queryResult.result && queryResult.result[0]) {
      keys = Object.keys(queryResult.result[0])

      return (
        <ResponsiveContainer witdh={this.props.width || '100%'} height={this.props.height || 300}>
          <PieChart>
            <Pie isAnimationActive={false} data={queryResult.result} nameKey={keys[0]} dataKey={keys[1]} cx='50%' cy='50%' outterRadius={100}>
              {queryResult.result.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Pie>
            <Tooltip />
            <Legend iconType='circle' layout='vertical' align='right' verticalAlign='middle' formatter={this.renderColorfulLegendText} />
          </PieChart>
        </ResponsiveContainer>
      )
    } else {
      return (
        <div className='col no-results'>
          <span>No data</span>
        </div>
      )
    }
  }

  render () {
    let {title, queryResult} = this.props
    let warning = queryResult && queryResult.result.length === 0
    let error = queryResult && queryResult.error
    let classNames = classnames('background', {warning: warning, error: error})
    return (
      <div className={`background ${classNames}`}>
        <div className='title'>
          <span>{title}</span>
        </div>
        <div className='chart pie-chart'>
          {this.renderChart()}
        </div>
      </div>
    )
  }
}
