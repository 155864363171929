// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import WarningIcon from '@material-ui/icons/Warning'

import './index.css'

export default class LookerSingleValue extends Component {
  renderCard () {
    let {queryResult, title} = this.props
    let formattedTitle = title.replace('@', '')
    let value = '...'
    let unit = null

    let hasErrors = queryResult && (queryResult.error || !queryResult.result.length)

    if (queryResult && !hasErrors) {
      if (queryResult.result) {
        let result = _.values(queryResult.result[0])
        if (result.length > 1) {
          value = result[1]
          unit = `At @${result[0]}`
        } else {
          value = result[0]
        }
      }
    }

    let valueElement = null
    let warning = false

    if (hasErrors || value === null) {
      valueElement = (
        <div className='value no-results'>
          <span><WarningIcon />&nbsp; No data available</span>
        </div>
      )
      warning = true
    } else {

      if (_.isFinite(value) && value % 1 !== 0) {
        value = _.round(value, 2)
      }

      valueElement = <span>{value}</span>
    }

    let classNames = classnames('background', {warning: warning})

    return (
      <>
        <div className={classNames}>
          <div className='single-value-inner'>
            <div className='single-value-body'>
              <div className='value'>
                {valueElement}
              </div>
            </div>
            <div className='single-value-text'>
              <div className='single-value-title'>
                <span>{formattedTitle}</span>
              </div>
              {unit &&
            <div className='single-value-unit'>
              <span>{unit}</span>
            </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  render () {
    let {id} = this.props
    return (
      <div key={id} className='single-value-container col-12 col-sm-6 col-md-2'>
        {this.renderCard()}
      </div>
    )
  }
}
