// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import React, {Component} from 'react'
import {connect} from 'react-redux'

import {renderFormField} from '../../../../../../utils/formFields'
import {fetchDomains} from '../../../../../../actions/domainManagement'

import Table from '../../../../../Table'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import SearchIcon from '@material-ui/icons/Search'

import './index.css'

class DomainsTable extends Component {
  tableHeaders () {
    return (
      <>
        <div className='th col'>
          Domain ID
          <div className='row field-with-icon'>
            {renderFormField('domainId', 'text', 'Enter full or partial ID', 'col-md-10')}
            <SearchIcon/>
          </div>
        </div>
        <div className='th col align-bottom align-center'>
          Devices assigned <br/> to this domain
        </div>
        <div className='th col align-bottom align-center '>
          Max number <br/> of devices
        </div>
        <div className='th col align-bottom align-center '>
         MAC Address
        </div>
        <div className='th col align-bottom align-right'>
          <CalendarTodayOutlinedIcon/>&nbsp; Start Date
        </div>
        <div className='th col align-bottom'>
          <AccessTimeIcon/>&nbsp; Start Time
        </div>
        <div className='th col align-bottom align-right'>
          <CalendarTodayOutlinedIcon/>&nbsp; End Date
        </div>
        <div className='th col align-bottom'>
          <AccessTimeIcon/>&nbsp; End Time
        </div>
        <div className='th col align-bottom align-center'>
          <button className='btn btn-search'>Search</button>
        </div>
      </>
    )
  }

  renderModalButtons (onClick) {
    return (
      <div className='col-md-12 align-center'>
        <button className='btn btn-purple col-md-2' onClick={onClick} type='submit'> OK </button>
      </div>
    )
  }

  infoFields = (item) => {
    return (
      <>
        <div className='td col'>
          <span>{item.domainId}</span>
        </div>
        <div className='td col align-center'>
          <span>{item.devicesAssigned}</span>
        </div>
        <div className='td col align-center'>
          <span>{item.maxNumberOfDevices}</span>
        </div>
        <div className='td col align-center'>
          <span>{item.macAddress}</span>
        </div>
        <div className='td col align-right'>
          <span>{item.startDate}</span>
        </div>
        <div className='td col'>
          <span>{item.startTime}</span>
        </div>
        <div className='td col align-right'>
          <span>{item.endDate}</span>
        </div>
        <div className='td col'>
          <span>{item.endTime}</span>
        </div>
        <div className='td col'>
        </div>
      </>
    )
  }

  render () {
    return (
      <div className='domains-table'>
        <Table
          tableHeaders={this.tableHeaders()}
          items={this.props.domainManagement.domains}
          infoFields={this.infoFields}
          showItemsCount={true}
        />
      </div>
    )
  }
}

function mapStateToProps ({session, domainManagement}) {
  return {session, domainManagement}
}

export default connect(mapStateToProps, {fetchDomains})(DomainsTable)
