// Copyright © 2020 Verimatrix, Inc. All rights reserved.

import axios from 'axios'
import config, {LOOKER_PROXY_API} from '../config'
import moment from 'moment'
import {showComponentSpinner} from './spinner'

export const LOOKER_GET_DASHBOARD = 'looker_get_dashboard'
export const LOOKER_INLINE_QUERY = 'looker_inline_query'
export const LOOKER_CLEAR_DASHBOARD = 'looker_clear_dashboard'
export const LOOKER_CLEAR_QUERIES = 'looker_clear_queries'

export const IGNORED_ITEMS = ['Data Refresh', 'Copyright', 'Blank Tile']


export function lookerGetDashboard (dashboard_id) {
  return (dispatch, getState) => {
    const {session} = getState()

    const headers = {
      headers: {
        Authorization: session.token,
        'Content-Type': 'application/json'
      }
    }

    dispatch({type: LOOKER_CLEAR_DASHBOARD})
    dispatch(showComponentSpinner(true))

    return axios.get(`${config[LOOKER_PROXY_API]}/dashboards/${dashboard_id}`, headers)
      .then(data => {
        dispatch({
          type: LOOKER_GET_DASHBOARD,
          payload: data.data
        })
        dispatch(showComponentSpinner(false))
      })
  }
}

export function clearQueries () {
  return (dispatch) => {
    dispatch({type: LOOKER_CLEAR_QUERIES})
  }
}

function formatDateString (date_string) {
  let date = new Date(date_string)

  let options = {year: 'numeric', month: '2-digit', day: '2-digit'}
  const dtf = new Intl.DateTimeFormat('en', options)

  let [{value: month}, , {value: day}, , {value: year}] = dtf.formatToParts(date)

  let formatted_date = `${year}/${month}/${day}`
  return formatted_date
}

function formatTimeString (time_string) {
  let date = moment(time_string)
  let formatted_time = date.format('HH:mm')
  return formatted_time
}

export function lookerInlineQuery (dashboard_element, filters = null) {
  return (dispatch, getState) => {
    const {looker, session} = getState()

    const headers = {
      headers: {
        Authorization: session.token,
        'Content-Type': 'application/json'
      }
    }

    let {result_maker_id, title, result_maker, id} = dashboard_element
    let {query} = result_maker

    let {dashboard_filters} = looker.dashboard
    let dimension = null

    let keys = Object.keys(dashboard_filters)

    keys.forEach(key => {
      let element = dashboard_filters[key]

      if (element.field && element.field.type === 'date_minute') {
        dimension = element.dimension
      }
    })

    // TODO: Remove this after IPTV dashboard filter fix
    if (dashboard_element.dashboard_id === 357) {
      dimension = 'srvc_vcas_iptv.ts_date'
    }

    // Clear client_id to avoid duplicated client_id error
    query['client_id'] = ''

    if (!query.filters) {
      query.filters = {}
    }

    if (filters) {
      let date_from_value = filters['dateFrom']
      let date_from = formatDateString(date_from_value)

      let time_from_value = filters['timeFrom']
      let time_from = formatTimeString(time_from_value)

      let date_to_value = filters['dateTo']
      let date_to = formatDateString(date_to_value)

      let time_to_value = filters['timeTo']
      let time_to = formatTimeString(time_to_value)

      query.filters[dimension] = `${date_from} ${time_from} to ${date_to} ${time_to}`
    }

    let {vis_config} = query
    let {type, hidden_fields} = vis_config

    let query_filters = query.filters

    return axios.post(`${config[LOOKER_PROXY_API]}/queries?cache=true`, query, headers)
      .then(data => {

        let error = null
        try {
          error = data.data[0].looker_error
        } catch (e) {
          error = null
        }

        let queryResult = {
          'dashboard_element_id': id,
          'result_maker_id': result_maker_id,
          'title': title,
          'result': data.data,
          'type': type,
          'applied_filters': query_filters,
          'hidden_fields': hidden_fields,
          'error': error,
        }

        if (!IGNORED_ITEMS.includes(title)) {
          dispatch({
            type: LOOKER_INLINE_QUERY,
            payload: queryResult
          })
        }
      })
  }
}
