import React, {Component} from 'react'

export class AdminIconComponent extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='130.44' height='119.93' viewBox='0 0 130.44 119.93' className={'admin-icon'}>
        <title>Admin Material Outline</title>
        <circle cx='62' cy='27.41' r='22.41' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <path d='M74.71,76.62A76.32,76.32,0,0,0,61,75.41s-56,0-56,36H75' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <circle cx='107.58' cy='96.41' r='14.24' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='107.58' y1='72.9' x2='107.58' y2='80.53' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='127.94' y1='84.66' x2='121.34' y2='88.47' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='127.94' y1='108.17' x2='121.33' y2='104.36' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='107.57' y1='119.92' x2='107.57' y2='112.3' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='87.21' y1='108.16' x2='93.82' y2='104.35' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <line x1='87.22' y1='84.65' x2='93.82' y2='88.46' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
      </svg>
    )
  }
}

export class UserIconComponent extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='122' height='116.41' viewBox='0 0 122 116.41'>
        <title>User Material Outline</title>
        <circle cx='62' cy='27.41' r='22.41' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
        <path d='M61,111.41h56c0-36-56-36-56-36s-56,0-56,36Z' fill='none' stroke={this.props.color || '#774699'} strokeLinejoin='round' strokeWidth='10'/>
      </svg>
    )
  }
}

export class EntitlementsIconComponent extends Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='47.867' height='47.867' viewBox='0 0 47.867 47.867' className='entitlements-icon'>
        <defs>
          <style>
          </style>
        </defs>
        <path class='a' d='M0,0H47.867V47.867H0Z' style={{fill: 'none'}}/>
        <path class='b' d='M7.978,9.186h35.9V4H7.978C5.784,4,3.989,6.334,3.989,9.186V37.707H0v7.778H27.923V37.707H7.978Zm37.9,5.186H33.906a2.361,2.361,0,0,0-1.994,2.593V42.892a2.361,2.361,0,0,0,1.994,2.593H45.873a2.361,2.361,0,0,0,1.994-2.593V16.964A2.361,2.361,0,0,0,45.873,14.371ZM43.878,37.707H35.9V19.557h7.978Z'
          transform='translate(0 -0.809)'
          style={{fill: '#df1783'}}
        />
      </svg>
    )
  }
}
